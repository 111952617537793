import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid';
import { Column, DatasetTableRow } from 'api/generated';
import EmptyState from 'components/EmptyState';
import FadeTransition from 'components/FadeTransition';
import { FilterGroupType, FilterConfiguration } from 'components/Filter/types';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import { getDatasetsQueryKey as getConceptDatasetsQueryKey } from 'pages/concepts/queries';
import CreateDatasetButton from 'pages/datasets/components/CreateDatasetButton';
import {
  invalidateDatasetQueries,
  useDeleteDatasetMutation,
} from 'queries/datasets';
import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaginationState } from 'types/pagination';
import { TablePageSize } from 'types/table';

interface TableViewProps {
  columns: Column[];
  datasets: DatasetTableRow[];
  total: number;
  refetchDatasets: () => void;
  loading?: boolean;
  pagination: PaginationState;
  setPageSize: (size: TablePageSize) => void;
  loadPage: (page: number) => void;
  activeFilters: FilterConfiguration;
  setActiveFilters: (activeFilters: FilterConfiguration) => void;
  filterGroups: FilterGroupType[];
  onQuerySearch: (value: string) => void;
  queryValue: string;
}

const TableView: React.FunctionComponent<TableViewProps> = function TableView({
  columns,
  datasets,
  total,
  refetchDatasets,
  loading,
  pagination,
  setPageSize,
  loadPage,
  activeFilters,
  setActiveFilters,
  filterGroups,
  onQuerySearch,
  queryValue,
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteDataset } = useDeleteDatasetMutation();
  const loadingTable = useMemo(() => <LoadingTable />, []);

  console.log(Object.values(activeFilters).flat());

  return (
    <>
      <FadeTransition show={Boolean(!loading && datasets)} appear>
        <div className="mt-2">
          <Table
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            filterGroups={filterGroups}
            queryValue={queryValue}
            onQuerySearch={(val) => onQuerySearch(val)}
            dataTestId="dataset"
            columns={columns}
            data={datasets as any}
            idKey="datasetId"
            actions={[
              {
                key: 'create',
                label: 'Create Concept',
                dynamicHide: (row: DatasetTableRow) => !row.data.isDeletable,
                action: (row: DatasetTableRow) =>
                  navigate({
                    pathname: '/concepts/create',
                    search: `?datasetId=${row.data.datasetId}`,
                  }),
              },
              {
                key: 'delete',
                label: 'Delete Dataset',
                danger: true,
                dataTestId: 'delete-dataset',
                dynamicHide: (row: DatasetTableRow) => !row.data.isDeletable,
                action: (row: DatasetTableRow) => {
                  deleteDataset(
                    { datasetId: row.data.datasetId },
                    {
                      onSuccess: () => {
                        refetchDatasets();
                      },
                      onError: () => {
                        toast.error(
                          `Unable to delete dataset ${row.data.name}. Try Again.`,
                        );
                      },
                    },
                  );
                  invalidateDatasetQueries(queryClient);
                  queryClient.invalidateQueries(getConceptDatasetsQueryKey());
                },
              },
            ]}
            total={total}
            emptyMessage={
              <div className="text-center py-12 px-2 text-sm">
                <EmptyState
                  primaryText={
                    queryValue.trim().length > 0
                      ? `No datasets match '${queryValue}'`
                      : undefined
                  }
                  iconClassName="h-6 w-6 text-gray-400"
                  Icon={ArchiveBoxXMarkIcon}
                  callToAction={<CreateDatasetButton buttonStyle="secondary" />}
                />
              </div>
            }
            loadStrategy="paginated"
            activePage={pagination.page}
            pageSize={pagination.size}
            setPageSize={setPageSize}
            loadPage={loadPage}
          />
        </div>
      </FadeTransition>
      <FadeTransition
        show={Boolean(loading)}
        appear
        enterDelayMs={500}
        exit={false}
      >
        {!!loading && loadingTable}
      </FadeTransition>
    </>
  );
};

TableView.defaultProps = {
  loading: false,
};
export default TableView;
