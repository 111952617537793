import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks/useOutsideClick';
import React, { ChangeEvent, useRef, useState } from 'react';
import Button from './Button';
import TextInput from './TextInput';

interface SearchBarProps {
  onSubmit: (value: string) => void;
  className?: string;
  hasToggleButton?: boolean;
  value?: string;
  placeholder?: string;
  onClear?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  className,
  hasToggleButton,
  onSubmit,
  value,
  placeholder,
  onClear,
}) => {
  const [inputValue, setInputValue] = useState<string>(value || '');
  const inputRef = useRef<HTMLInputElement>(null);
  const isInputValueEmpty = inputValue.trim().length === 0;
  const [isInputVisible, setIsInputVisible] = useState<boolean>(
    !hasToggleButton || value!.trim().length > 0,
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' && hasToggleButton) {
      setInputValue('');
      setIsInputVisible(false);
    }
  };

  const handleOutsideClick = () => {
    if (isInputValueEmpty && hasToggleButton && value?.trim().length === 0) {
      setIsInputVisible(false);
    }
  };

  const handleClear = () => {
    onClear?.();
    setInputValue('');
  };

  useOutsideClick(inputRef, handleOutsideClick);

  const isSubmitSearchDisabled =
    (value && value === inputValue) || isInputValueEmpty;

  return (
    <>
      {hasToggleButton && !isInputVisible && (
        <Button
          textColor="text-green-700"
          className="bg-white text-green-700 hover:bg-gray-100 !shadow-none"
          onClick={() => setIsInputVisible(true)}
        >
          Search <MagnifyingGlassIcon className="h-4 w-4 ml-2" />
        </Button>
      )}
      {isInputVisible && (
        <TextInput
          actionButtons={
            (value!.trim().length > 0 || !isInputValueEmpty) && (
              <button type="button" onClick={handleClear}>
                <XCircleIcon className="h4 w-4 text-gray-400" />
              </button>
            )
          }
          containerClassName={className}
          ref={inputRef}
          onKeyDown={(e) => handleKeyDown(e)}
          submitButton={{
            component: (
              <ArrowRightIcon
                className={classNames(
                  ' h-4 w-4',
                  isSubmitSearchDisabled ? 'text-gray-400' : 'text-green-700',
                )}
              />
            ),
            onClick: () => onSubmit(inputValue),
            disabled: isSubmitSearchDisabled,
          }}
          placeholder={placeholder}
          icon={<MagnifyingGlassIcon className="h-4 w-4 text-green-700" />}
          value={inputValue}
          id="table-searchBar"
          name="table-searchBar"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
        />
      )}
    </>
  );
};

SearchBar.defaultProps = {
  value: '',
  className: undefined,
  hasToggleButton: false,
  placeholder: '',
  onClear: undefined,
};
export default SearchBar;
