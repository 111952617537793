import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export const textToRegExp = (text: string) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const highlightQuery = (
  text: string,
  query?: string,
): React.JSX.Element => {
  if (!query) return <p>{text}</p>;

  const regexQuery = textToRegExp(query);
  // g flag for all matches, i flag for case insensitive
  const regex = new RegExp(`(${regexQuery})`, 'gi');
  const parts = text.split(regex);

  return (
    <p>
      {parts.map((part) =>
        regex.test(part) ? (
          <strong className="font-extrabold" key={uuidv4()}>
            {part}
          </strong>
        ) : (
          part
        ),
      )}
    </p>
  );
};
