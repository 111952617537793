import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid';
import { DatasetStatusEnum } from 'api/generated';
import EmptyState from 'components/EmptyState';
import FadeTransition from 'components/FadeTransition';
import { DATASET_FILTERS } from 'components/Filter/constants';
import { FilterConfiguration } from 'components/Filter/types';
import LoadingTable from 'components/LoadingTable';
import Main from 'components/Main';
import PageHeader from 'components/PageHeader';
import usePagination from 'hooks/usePagination';
import TableView from 'pages/datasets/list/components/TableView';
import { useGetDatasetsAsTableQuery } from 'queries/datasets';
import React, { useMemo, useState } from 'react';
import { PaginationState } from 'types/pagination';
import { textToRegExp } from 'utils/FormatTextUtils';
import CreateDatasetButton from '../components/CreateDatasetButton';

const DEFAULT_DATASET_FILTERS = DATASET_FILTERS.reduce((acc, curr) => {
  acc[curr.id] = [];
  return acc;
}, {});

const Datasets = function Datasets() {
  const [nameSearchQuery, setNameSearchQuery] = useState<string>('');
  const pagination = usePagination();
  const [activeFilters, setActiveFilters] = useState<FilterConfiguration>(
    DEFAULT_DATASET_FILTERS,
  );

  const {
    data: datasets,
    isLoading,
    isFetched,
    refetch,
  } = useGetDatasetsAsTableQuery({
    limit: pagination.size,
    offset: (pagination.page - 1) * pagination.size,
    statusesToInclude:
      activeFilters.status.length > 0
        ? (activeFilters.status as DatasetStatusEnum[])
        : undefined,
    regexpPattern: textToRegExp(nameSearchQuery),
  });

  const paginationState = useMemo<PaginationState>(
    () => ({ page: pagination.page, size: pagination.size }),
    [pagination.page, pagination.size],
  );
  const loadingTable = useMemo(() => <LoadingTable />, []);
  const loading = isLoading && !isFetched;

  const hasNoQueryOrFilters =
    Object.values(activeFilters).flat().length === 0 &&
    nameSearchQuery.trim().length === 0;

  const hasNoDatasetsCreated =
    datasets?.data.length === 0 && hasNoQueryOrFilters;

  return (
    <Main>
      <div className="max-w-8xl mx-auto">
        <PageHeader
          title={<h1>Datasets</h1>}
          backButtonTo=""
          actions={<CreateDatasetButton />}
        />
        <div className="pt-8">
          <FadeTransition show={Boolean(!loading && datasets)} appear>
            {datasets &&
              (hasNoDatasetsCreated ? (
                <div className="h-[35vh] items-center flex">
                  <EmptyState
                    iconClassName="h-6 w-6 text-gray-400"
                    Icon={ArchiveBoxXMarkIcon}
                    callToAction={<CreateDatasetButton />}
                  />
                </div>
              ) : (
                <TableView
                  filterGroups={DATASET_FILTERS}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  onQuerySearch={(val) => setNameSearchQuery(val)}
                  queryValue={nameSearchQuery}
                  datasets={datasets?.data}
                  columns={datasets?.columns}
                  total={datasets?.meta?.page?.total || 0}
                  refetchDatasets={refetch}
                  loading={isLoading}
                  setPageSize={pagination.setSize}
                  loadPage={pagination.setPage}
                  pagination={paginationState}
                />
              ))}
          </FadeTransition>
          <FadeTransition show={loading} appear enterDelayMs={500} exit={false}>
            {loading && loadingTable}
          </FadeTransition>
        </div>
      </div>
    </Main>
  );
};

export default Datasets;
