import { Popover } from '@headlessui/react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Button from '../Button';
import FilterGroup from './FilterGroup';
import { FilterConfiguration, FilterGroupType } from './types';

interface FiltersProps {
  activeFilters: FilterConfiguration;
  setActiveFilters: (activeFilters: FilterConfiguration) => void;
  filterGroups: FilterGroupType[];
}

const getAddFilterButtonLabel = (
  activeFilterCount: number,
  selectedFilterCount: number,
) => {
  if (activeFilterCount === 0)
    return selectedFilterCount === 0
      ? 'Add filter'
      : `Add ${selectedFilterCount === 0 ? '' : selectedFilterCount} filter${
          selectedFilterCount > 1 ? 's' : ''
        }`;
  return 'Update filters';
};

const Filters: React.FC<FiltersProps> = ({
  activeFilters,
  setActiveFilters,
  filterGroups,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<any>(activeFilters);
  const [openGroupIdx, setOpenGroupIdx] = useState<number | null>(null);
  // Needed to calculate animating closing group
  const [pendingOpenGroupIdx, setPendingOpenGroupIdx] = useState<number | null>(
    null,
  );

  const hasNoChanges =
    JSON.stringify(activeFilters) === JSON.stringify(selectedFilters);

  const handleToggle = (idx: number) => {
    if (openGroupIdx === idx) {
      setOpenGroupIdx(null);
    } else if (openGroupIdx !== null) {
      setPendingOpenGroupIdx(idx);
      setOpenGroupIdx(null);
    } else {
      setOpenGroupIdx(idx);
    }
  };

  const activeFilterCount = Object.values(activeFilters).flat().length;
  const selectedFilterCount = Object.values(selectedFilters).flat().length;

  return (
    <Popover className="relative">
      {({ open, close }) => {
        // State needs to be handled this way, external open state doesn't work
        useEffect(() => {
          if (!open) {
            setOpenGroupIdx(null);
          }
        }, [open]);

        return (
          <>
            <Popover.Button>
              <Button
                textColor="text-green-700"
                className={classNames(
                  'bg-white text-green-700 hover:bg-gray-100 !shadow-none',
                )}
                color={open ? '!bg-gray-100 text-green-800' : undefined}
                onClick={() => null}
              >
                Filter {activeFilterCount > 0 && `(${activeFilterCount})`}
                <AdjustmentsHorizontalIcon className="ml-2 h-5 w-5 inline-block" />
              </Button>
            </Popover.Button>
            {open && (
              <Popover.Panel className="mt-1 shadow-sm w-96 absolute z-10 bg-white rounded-md py-2 border-2 border-gray-100">
                <div className="py-2 px-4 space-y-4">
                  {filterGroups.map((filterGroup, idx) => (
                    <FilterGroup
                      isOpen={Boolean(openGroupIdx === idx)}
                      onToggle={() => handleToggle(idx)}
                      onAnimationEnd={() => {
                        if (pendingOpenGroupIdx !== null) {
                          setOpenGroupIdx(pendingOpenGroupIdx);
                          setPendingOpenGroupIdx(null);
                        }
                      }}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                      key={filterGroup.id}
                      filterGroup={filterGroup}
                    />
                  ))}
                </div>
                <div className="border-t border-gray-100 px-4 pt-2 text-end">
                  <Button
                    disabled={hasNoChanges}
                    onClick={() => {
                      setActiveFilters(selectedFilters);
                      close();
                    }}
                  >
                    {getAddFilterButtonLabel(
                      activeFilterCount,
                      selectedFilterCount,
                    )}
                  </Button>
                </div>
              </Popover.Panel>
            )}
          </>
        );
      }}
    </Popover>
  );
};

export default Filters;
