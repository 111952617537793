import classNames from 'classnames';
import React, { ChangeEventHandler } from 'react';

interface CheckboxProps {
  checked: boolean;
  id: string;
  name: string;
  label: string;
  align?: 'left' | 'right';
  description?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  srOnlyText?: string;
}

const Checkbox = function Checkbox({
  align,
  checked,
  description,
  disabled,
  id,
  label,
  name,
  onChange,
  srOnlyText,
}: CheckboxProps) {
  return (
    <fieldset className="space-y-5">
      <legend className="sr-only">{srOnlyText}</legend>
      <div
        className={classNames(
          'relative flex items-start space-x-3',
          align === 'right' ? 'flex-row-reverse space-x-reverse' : 'flex-row',
        )}
      >
        <div className="flex items-center h-5">
          <input
            id={id}
            aria-describedby={description ? `${id}-description` : undefined}
            name={name}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
          />
        </div>
        <div className="text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {label}
          </label>
          <span id={`${id}-description`} className="text-gray-500">
            {description}
          </span>
        </div>
      </div>
    </fieldset>
  );
};

Checkbox.defaultProps = {
  align: 'left',
  description: undefined,
  onChange: undefined,
  disabled: false,
  srOnlyText: undefined,
};

export default Checkbox;
