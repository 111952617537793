import { DatasetStatusEnum } from 'api/generated';
// import { FilterGroupComponent } from './types';

export const DATASET_FILTERS = [
  {
    id: 'status',
    name: 'STATUS',
    filters: [
      { name: 'Ready', value: DatasetStatusEnum.Ready },
      { name: 'Initializing', value: DatasetStatusEnum.Initializing },
      { name: 'Waiting', value: DatasetStatusEnum.Waiting },
      { name: 'Incomplete', value: DatasetStatusEnum.Incomplete },
      { name: 'Error', value: DatasetStatusEnum.Error },
      { name: 'Deleting', value: DatasetStatusEnum.Deleting },
    ],
  },
  // {
  //   id: 'content',
  //   name: 'CONTENT',
  //   filters: [
  //     { name: 'Has images', value: 'images' },
  //     { name: 'Has videos', value: 'videos' },
  //   ],
  // },
  // {
  //   id: 'createdDt',
  //   name: 'CREATED',
  //   component: FilterGroupComponent.SingleSelect,
  //   filters: [
  //     { name: 'Last 24 hours', value: '24hrs' },
  //     { name: 'Last week', value: 'week' },
  //     { name: 'Last month', value: 'month' },
  //     { name: 'Last year', value: 'yr' },
  //   ],
  // },
  // {
  //   id: 'updatedDt',
  //   name: 'LAST UPDATED',
  //   component: FilterGroupComponent.SingleSelect,
  //   filters: [
  //     { name: 'Last 24 hours', value: '24hrs' },
  //     { name: 'Last week', value: 'week' },
  //     { name: 'Last month', value: 'month' },
  //     { name: 'Last year', value: 'yr' },
  //   ],
  // },
];
