import React from 'react';
import Button, { ButtonStyle } from 'components/Button';
import { useNavigate } from 'react-router-dom';

interface CreateDatasetButtonProps {
  buttonStyle?: ButtonStyle;
}

const CreateDatasetButton: React.FC<CreateDatasetButtonProps> = ({
  buttonStyle,
}) => {
  const navigate = useNavigate();

  return (
    <Button
      buttonStyle={buttonStyle}
      dataTestId="create-dataset"
      onClick={() => navigate('/datasets/create')}
    >
      Create a Dataset
    </Button>
  );
};

CreateDatasetButton.defaultProps = {
  buttonStyle: 'primary',
};

export default CreateDatasetButton;
