import { Column, ColumnDisplayType, ColumnStyle } from 'api/generated';
import classNames from 'classnames';
import { BadgeList } from 'components/BadgeList';
import { ImageSetPreview } from 'components/ImageSetPreview';
import { TableRow } from 'components/Table/types';
import Tooltip from 'components/Tooltip';
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppendedCellContent } from 'types/table';
import { formatPath } from 'utils/NavigationUtils';
import { v4 as uuidv4 } from 'uuid';
import { highlightQuery } from 'utils/FormatTextUtils';
import ImagePreview from './ImagePreview';
import Pills from './Pills';
import SecretValue from './SecretValue';
import Status from './Status';
import Timestamp from './Timestamp';

interface TableBodyCellProps<T extends TableRow> {
  column: Column;
  row: T;
  cellValue: any;
  isLastCell: boolean;
  onClickImagePreview?: (data: TableRow) => void;
  appendedCellContent?: AppendedCellContent;
  cellId: string;
  queryValue?: string;
}

const TableBodyCell = <T extends TableRow>({
  column,
  row,
  cellId,
  cellValue,
  isLastCell,
  onClickImagePreview,
  appendedCellContent,
  queryValue,
}: TableBodyCellProps<T>) => {
  const tooltipId = uuidv4();
  const contentRef = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const measureTruncation = () => {
    if (contentRef.current) {
      const { scrollWidth, clientWidth } = contentRef.current;
      const truncated = scrollWidth > clientWidth;
      setIsTruncated(truncated);
    }
  };

  useLayoutEffect(() => {
    const handle = requestAnimationFrame(measureTruncation);
    return () => {
      cancelAnimationFrame(handle);
    };
  }, [cellValue, cellId, column.displayType, column.to]);

  useEffect(() => {
    let resizeTimeout: number | undefined;

    const onResize = () => {
      if (resizeTimeout) window.clearTimeout(resizeTimeout);
      resizeTimeout = window.setTimeout(() => {
        measureTruncation();
      }, 200);
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      if (resizeTimeout) {
        window.clearTimeout(resizeTimeout);
      }
    };
  }, [cellId, column.displayType, column.to]);

  const cellClassNames = classNames(
    column.key !== 'row' && 'text-sm font-medium',
    {
      'px-4 py-3':
        column.displayType !== ColumnDisplayType.Image && column.key !== 'row',
      'text-gray-900':
        column.style === ColumnStyle.Primary &&
        column.displayType !== ColumnDisplayType.Action,
      'text-gray-600':
        column.style !== ColumnStyle.Primary &&
        column.displayType !== ColumnDisplayType.Action,
      'text-right':
        column.displayType === ColumnDisplayType.Action && isLastCell,
      truncate:
        column.displayType === ColumnDisplayType.RelativeTimestamp ||
        column.displayType === ColumnDisplayType.Status,
      'overflow-hidden': true,
      'font-medium': column.displayType === ColumnDisplayType.Action,
      'overflow-visible': column.displayType === ColumnDisplayType.Image,
      'sr-only': column.key === 'row',
      // Responsive general values
      'max-w-[100px]': true,
      'sm:max-w-[150px]': true,
      'md:max-w-[200px]': true,
      'lg:max-w-[250px]': true,
      'xl:max-w-[300px]': true,
      '2xl:max-w-[350px]': true,
    },
  );

  const contentClassNames = classNames('truncate', 'w-full', 'block');

  const getAppendedContent = () => {
    if (appendedCellContent && appendedCellContent.cellIds.includes(cellId)) {
      return (
        <span className={classNames('ml-2', appendedCellContent.className)}>
          {appendedCellContent.text}
        </span>
      );
    }
    return null;
  };

  return (
    <td data-cy={cellId} key={column.key} className={cellClassNames}>
      {isTruncated && cellValue && (
        <Tooltip id={`${cellValue}-${tooltipId}`}>
          <span>{cellValue}</span>
        </Tooltip>
      )}

      {column.displayType === ColumnDisplayType.Action && column.to && (
        <Link
          to={formatPath(column.to, row?.data!)}
          className="text-blue-500 hover:text-blue-600"
        >
          <span
            ref={contentRef}
            className={contentClassNames}
            data-tooltip-id={
              isTruncated ? `${cellValue}-${tooltipId}` : undefined
            }
            data-tooltip-content={isTruncated ? cellValue : undefined}
            data-tooltip-place="top"
          >
            {highlightQuery(cellValue, queryValue) || column.label}
            {getAppendedContent()}
          </span>
        </Link>
      )}

      {column.displayType === ColumnDisplayType.Status && (
        <Status status={cellValue} />
      )}

      {column.displayType === ColumnDisplayType.RelativeTimestamp && (
        <Timestamp datetime={cellValue} />
      )}

      {column.displayType === ColumnDisplayType.Secret && (
        <SecretValue value={cellValue} />
      )}

      {column.displayType === ColumnDisplayType.Image && (
        <ImagePreview
          value={cellValue}
          row={row}
          onClickImagePreview={onClickImagePreview}
        />
      )}

      {column.displayType === ColumnDisplayType.Pill && (
        <Pills value={cellValue} />
      )}

      {column.displayType === ColumnDisplayType.BadgeList && (
        <BadgeList elements={cellValue} maxElementsToShow={4} />
      )}

      {column.displayType === ColumnDisplayType.ImageSetPreview && (
        <ImageSetPreview
          images={cellValue}
          maxImagesToShow={3}
          className="h-8"
        />
      )}

      {(column.displayType === ColumnDisplayType.Default ||
        !column.displayType) &&
        (cellValue === undefined || cellValue === null ? (
          <span className="text-gray-400">{column.emptyText}</span>
        ) : (
          <span
            ref={contentRef}
            className={contentClassNames}
            data-tooltip-id={
              isTruncated ? `${cellValue}-${tooltipId}` : undefined
            }
            data-tooltip-content={isTruncated ? cellValue : undefined}
            data-tooltip-place="top"
          >
            {cellValue}
            {getAppendedContent()}
          </span>
        ))}
    </td>
  );
};

TableBodyCell.defaultProps = {
  appendedCellContent: undefined,
  onClickImagePreview: undefined,
  queryValue: undefined,
};

export default TableBodyCell;
