export enum FilterGroupComponent {
  MultiSelect = 'multi-select',
  SingleSelect = 'single-select',
}

export type FilterGroupType = {
  id: string;
  name: string;
  component?:
    | FilterGroupComponent.SingleSelect
    | FilterGroupComponent.MultiSelect;
  filters: {
    name: string;
    value: string;
  }[];
};

export type FilterConfiguration = {
  [key: string]: string[];
};
